import React, { useEffect, useState } from 'react';
import './AuditorApproval.css'; // Import CSS file for styling
import { auditor as auditorAPI } from '@rap/api'; // Import API functions

interface Auditor {
  first_name: string;
  last_name: string;
  firm_name: string;
  firm_issuing_country: string;
  firm_issuing_state: string;
  firm_issuing_city: string;
  linkedin: string;
  note: string;
  status?: string;
  _id: string;
}

const AuditorApproval: React.FC = () => {
  const [submittedAuditors, setSubmittedAuditors] = useState<Auditor[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentNote, setCurrentNote] = useState<string | null>(null);
  
  useEffect(() => {
    const storedAuditors = localStorage.getItem('submittedAuditors');
    if (storedAuditors) {
      setSubmittedAuditors(JSON.parse(storedAuditors));
    }
    auditorAPI.getPendingAuditors().then((response) => {
        setSubmittedAuditors(response.data.auditors);
    });
    console.log('Auditors:', submittedAuditors)
  }, []);

  const updateSubmittedAuditors = (updatedAuditor: Auditor, index: number) => {
    const updatedAuditors = [...submittedAuditors];
    updatedAuditors[index] = updatedAuditor;
    setSubmittedAuditors(updatedAuditors); // Call API to update auditor status
    localStorage.setItem('submittedAuditors', JSON.stringify(updatedAuditors));
  };

  const handleApprove = (index: number) => {
    const auditor = submittedAuditors[index];
    const updatedAuditor = { ...auditor, status: 'approved' };
    updateSubmittedAuditors(updatedAuditor, index);
    auditorAPI.verifyAuditor(auditor._id);
    alert(`Auditor ${auditor.first_name} ${auditor.last_name} has been approved.`);
    location.reload()
  };

  const handleReject = (index: number) => {
    const auditor = submittedAuditors[index];
    const updatedAuditor = { ...auditor, status: 'rejected' };
    updateSubmittedAuditors(updatedAuditor, index);
    auditorAPI.rejectAuditor(auditor._id);
    alert(`Auditor ${auditor.first_name} ${auditor.last_name} has been rejected.`);
    location.reload()
  };

  const handleNoteClick = (note: string) => {
    setCurrentNote(note);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setCurrentNote(null);
  };

  return (
    <div>
      {submittedAuditors.length > 0 ? (
        <table className="submission-table">
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Firm Name</th>
              <th>Firm Country</th>
              <th>Firm State</th>
              <th>Firm City</th>
              <th>LinkedIn Profile</th>
              <th>Note</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {submittedAuditors.map((auditor, index) => (
              <tr key={index} className={`submission-item ${auditor.status}`}>
                <td>{auditor.first_name}</td>
                <td>{auditor.last_name}</td>
                <td>{auditor.firm_name}</td>
                <td>{auditor.firm_issuing_country}</td>
                <td>{auditor.firm_issuing_state}</td>
                <td>{auditor.firm_issuing_city}</td>
                <td>
                  <a href={auditor.linkedin} target="_blank"  rel="noopener noreferrer">
                    See Profile
                  </a>
                </td>
                <td>
                  <button className="note-button" onClick={() => handleNoteClick(auditor.note)}>View Note</button>
                </td>
                <td>{auditor.status}</td>
                <td>
                      <button className="approve-button" onClick={() => handleApprove(index)}>Approve</button>
                      <button className="reject-button" onClick={() => handleReject(index)}>Reject</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No submitted auditors found.</p>
      )}
      {modalVisible && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <h3>Note</h3>
            <p>{currentNote}</p>
            <button className="modal-close-button" onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AuditorApproval;
